import React from 'react';

import Layout from '../components/layout';

import '../components/style.scss';

export default () => (
  <Layout>
  <section className="section">
    <div className="container">
      <div className="columns">
        <div className="column is-4 is-offset-1">
          <div className="content">
            <h1>{faq.events.title}</h1>
            {faq.events.questions.map(item => (
              <div className="content is-small">
                <h2>{item.question}</h2>
                <p>{item.answer}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="column is-4 is-offset-2">
          <div className="content">
            <h1>{faq.portraits.title}</h1>
            {faq.portraits.questions.map(item => (
              <div className="content is-small">
                <h2>{item.question}</h2>
                <p>{item.answer.replace("\\n", "\n")}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
  </Layout>
)

const faq = {
  events: {
    title: "Events",
    questions: [{
      question: "When will the photos be ready?",
      answer: "You will have your images within 3 business days of your event or function."
    },{
      question: "Can images be ready the same night for media?",
      answer: "I can provide a quicker turnaround, usually within 24 hrs, if requested."
    },{
      question: "Do you sell photos on-site?",
      answer: "Not currently."
    },{
      question: "Are the photos edited?",
      answer: "Yes, I edit all photos to provide the highest quality results. At a minimum, editing includes color correction, contrast, noise removal and sharpening."
    },{
      question: "How many images will we get?",
      answer: "You can get up to 1000 images if required, but usually I make a selection to make your marketing and social media simpler. The selection will match your necessities and preferences."
    },{
      question: "How is the payment processed?",
      answer: "50% is required to book. The other 50% is due the day of the event."
    }]
  },
  portraits: {
    title: "Portraits",
    questions: [{
      question: "How many images will I get?",
      answer: "During the session, I’ll take around 40 images for each person. Within 2 business days, you can review the images in a web gallery and select your favorite 5 images. I’ll then edit the selected photos and deliver them electronically within 2 business days."
    },{
      question: "What I should wear?",
      answer: "Clothing - When choosing your clothes, think about how the resulting photo will be used; how formal do you want to look? For corporate photos, consider consistency.\n\n High contrast combinations, such as a dark jacket and tie with a white shirt work well, as do a distinctive scarf of accessories against a light top. Avoid pastel tones and patterns. Hair & Make-up - Women get the best results with freshly styled hair and professional natural matte make-up. Men get the best results with a recent haircut and a fresh shave."
    },{
      question: "Do you retouch the portraits?",
      answer: "I retouch all corporate and creatives portraits after agreeing on the level of retouching that you'd like utilise to enhance the image. I usually do a light to medium retouching and avoid any unrealistic skin retouching (sorry it is not my style). I like to finalise the images with a slight whitening of the eyes and teeth, blemish removal and general retouch for a polished and flattering result."
    },{
      question: "How is the payment process?",
      answer: "Payment for individual headshots are generally made on the day of the shoot. Payment on completion by invoice or purchase order is also available by prior arrangement."
    }]
  }
}
